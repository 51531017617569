"use client"

import ComplianceSection from "@/components/sections/home/ComplianceSection";
import HeroSection from "@/components/sections/home/HeroSection";
import LeavingOnTable from "@/components/sections/home/LeavingOnTableSection";
import LeftBehind from "@/components/sections/home/LeftBehindSection";
import WebAccessibilitySection from "@/components/sections/home/WebAccessibilitySection";
import WebFailsSection from "@/components/sections/home/WebFailsSection";

const HomePage = () => {
  return (
    <>
      <HeroSection isLoaded={true} />
      <WebAccessibilitySection />
      <WebFailsSection />
      <ComplianceSection />
      <LeavingOnTable />
      <LeftBehind />
    </>
  );
};

export default HomePage;
