"use client";

import React, { useEffect, useRef } from "react";
import Image from "next/image";
import BookNowButton from "@/app/components/ui/BookNowButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";
import { useContent } from "@/utils/hooks/useContent";

gsap.registerPlugin(ScrollTrigger);

const ComplianceSection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { animationsDisabled } = useAnimationDisableToggle();

  const subtitle = useContent("complianceSection", "subtitle");
  const title = useContent("complianceSection", "title");
  const descriptions = [
    useContent("complianceSection", "description.0"),
    useContent("complianceSection", "description.1"),
  ];
  const buttonText = useContent("complianceSection", "buttonText");

  useEffect(() => {
    const fadeInElements = sectionRef.current?.querySelectorAll(".fade-in");

    if (animationsDisabled) {
      fadeInElements?.forEach((el) => {
        (el as HTMLElement).style.opacity = "1";
      });
    } else if (fadeInElements && fadeInElements.length > 0) {
      gsap.fromTo(
        fadeInElements,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          ease: "power1.out",
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none none",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [animationsDisabled]);

  return (
    <section
      ref={sectionRef}
      id="compliance-section"
      className="bg-light text-blue py-8"
      aria-labelledby="compliance-title"
    >
      <div className="custom-container-lite">
        <h2
          id="compliance-subtitle"
          className="text-sm py-4 text-center fade-in"
        >
          {subtitle}
        </h2>
        <h1
          id="compliance-title"
          className="text-4xl xs:text-5xl md:text-6xl font-bold mb-4 text-center fade-in"
        >
          {title}
        </h1>

        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between mx-auto space-y-6 lg:space-y-0">
          <div className="flex-1 text-left lg:pr-4">
            {descriptions.map((description, index) => (
              <p
                key={index}
                className={`mb-4 text-md xs:text-lg md:text-xl ${
                  index === 0 ? "lg:pt-16 pt-4" : ""
                } fade-in`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ))}

            <div className="mt-6 sm:mt-8 flex flex-col mb-8 sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 fade-in">
              <BookNowButton
                showArrow={true}
                aria-label="Open booking modal"
                buttonText={buttonText}
                className="focus-button"
              />
            </div>
          </div>

          <div className="flex-1 flex justify-center lg:justify-end lg:pt-16 pt-0">
            <Image
              src="/images/home/compliance-image-png.png"
              alt=""
              width={400}
              height={400}
              quality={100}
              className="md:w-[600px] w-[400px] h-auto lg:max-w-lg fade-in"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComplianceSection;
