import React, { useState, useRef, useEffect, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import AnimatedButton from "./AnimatedButton";
import { X } from "lucide-react";

interface CalendlyPrefill {
  name?: string;
  email?: string;
  customAnswers?: Record<string, string>;
}

interface CalendlyUTM {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
}

interface Calendly {
  initInlineWidget: (options: {
    url: string;
    parentElement: HTMLElement;
    prefill?: CalendlyPrefill;
    utm?: CalendlyUTM;
  }) => void;
}

interface WindowWithCalendly extends Window {
  Calendly?: Calendly;
}

interface BookNowButtonProps {
  className?: string;
  "aria-label"?: string;
  showArrow?: boolean;
  buttonText?: string;
  variant?: "primary" | "secondary" | "outline" | "light";
  closeButtonClassName?: string;
  calendlyUrl?: string;
}

const BookNowButton: React.FC<BookNowButtonProps> = ({
  className,
  "aria-label": ariaLabel,
  showArrow = true,
  buttonText = "Book Consultation",
  variant = "primary",
  closeButtonClassName,
  calendlyUrl = "https://calendly.com/designdojo_io/30-min-consultation?primary_color=00bfff",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const buttonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);

  const initCalendly = useCallback(() => {
    if ((window as WindowWithCalendly).Calendly) {
      const container = document.getElementById('calendly-inline-widget');
      if (!container) return;

      setTimeout(() => {
        try {
          (window as WindowWithCalendly).Calendly?.initInlineWidget({
            url: calendlyUrl,
            parentElement: container,
            prefill: {},
            utm: {},
          });
        } catch (error) {
          console.error("Error initializing Calendly:", error);
        }
        setIsLoading(false);
      }, 200);
    }
  }, [calendlyUrl]);

  useEffect(() => {
    if (!isModalOpen) return;

    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      script.onload = () => initCalendly();
      document.body.appendChild(script);

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://assets.calendly.com/assets/external/widget.css";
      document.head.appendChild(link);

      return () => {
        if (script.parentNode) script.parentNode.removeChild(script);
        if (link.parentNode) link.parentNode.removeChild(link);
      };
    };

    setIsLoading(true);
    const cleanup = loadScript();
    closeButtonRef.current?.focus();

    return () => {
      cleanup();
      const container = document.getElementById('calendly-inline-widget');
      if (container) {
        container.innerHTML = '';
      }
    };
  }, [isModalOpen, initCalendly]);

  const handleClose = () => {
    setIsModalOpen(false);
    setIsLoading(true);
    buttonRef.current?.focus();
  };

  return (
    <div className="inline-block w-full sm:w-auto">
      <AnimatedButton
        ref={buttonRef}
        variant={variant}
        onClick={() => setIsModalOpen(true)}
        showArrow={showArrow}
        className={`w-full sm:w-auto ${className}`}
        aria-label={ariaLabel || "Open scheduling dialog"}
        aria-haspopup="dialog"
        aria-expanded={isModalOpen}
      >
        {buttonText}
      </AnimatedButton>

      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        className="relative z-50"
        initialFocus={closeButtonRef}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div
          className="fixed inset-0 flex items-center justify-center overflow-y-auto p-4"
          ref={dialogRef}
        >
          <Dialog.Panel className="relative w-full max-w-[800px] h-[80vh] rounded-lg bg-white shadow-xl sm:mx-auto max-[350px]:max-w-[280px] max-[350px]:mx-4">
            <div className="flex justify-between items-center p-4 border-b">
              <div id="dialog-title" className="text-xl font-semibold">
                Schedule a Consultation
              </div>
              <button
                ref={closeButtonRef}
                onClick={handleClose}
                className={`rounded-full bg-gray-100 p-2 hover:bg-gray-200 hover:ring-4 hover:ring-blue border-2 border-blue focus:outline-gray-200 focus:ring-4 focus:ring-blue !text-blue transition-all ${closeButtonClassName}`}
                aria-label="Close scheduling dialog"
              >
                <X className="h-6 w-6" />
                <span className="sr-only">Close dialog</span>
              </button>
            </div>

            {isLoading && (
              <div
                className="absolute inset-0 flex items-center justify-center bg-light"
                role="status"
                aria-label="Loading calendar"
              >
                <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-gray-900" />
              </div>
            )}

            <div
              id="calendly-inline-widget"
              className="h-full w-full overflow-hidden"
              style={{ height: "calc(100% - 73px)" }}
              aria-busy={isLoading}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default BookNowButton;