"use client";

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import BookNowButton from "@/app/components/ui/BookNowButton";
import AnimatedButton from "@/app/components/ui/AnimatedButton";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";
import { useContent } from "@/utils/hooks/useContent";

interface HeroSectionProps {
  isLoaded: boolean;
}

const HeroSection: React.FC<HeroSectionProps> = ({ isLoaded }) => {
  const { animationsDisabled } = useAnimationDisableToggle();
  const [showContent, setShowContent] = useState(false);

  const headline = useContent("heroSection", "headline");
  const subheadline = useContent("heroSection", "subheadline");
  const description = useContent("heroSection", "description");
  const consultationButton = useContent("heroSection", "buttons.consultation");
  const servicesButton = useContent("heroSection", "buttons.services");

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => setShowContent(true), 500);
      return () => clearTimeout(timer);
    }
  }, [isLoaded]);

  return (
    <section
      id="hero-section"
      className="relative w-full min-h-screen flex flex-col justify-center items-start bg-light text-blue"
      aria-label="Hero section for DesignDojo, providing accessibility consulting and web development services"
    >
      {showContent && (
        <motion.div
          className="w-full max-w-7xl mx-auto px-4 custom-container sm:px-6 lg:px-8 z-10 flex flex-col lg:flex-row items-center"
          initial={animationsDisabled ? false : { opacity: 0 }}
          animate={animationsDisabled ? {} : { opacity: 1 }}
          transition={
            animationsDisabled ? {} : { duration: 0.8, ease: "easeOut" }
          }
        >
          <div className="text-left max-w-3xl pr-0 lg:pr-16">
            <h1 className="text-4xl xs:text-5xl md:text-6xl font-bold pt-16">
              {headline}
              <br />
              <span className="font-thin text-3xl xs:text-4xl md:text-5xl">
                {subheadline}
              </span>
            </h1>
            <p className="mt-4 text-md xs:text-lg md:text-xl mb-8">
              {description}
            </p>

            <div
              className="mt-6 sm:mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6"
              role="group"
            >
              <BookNowButton
                buttonText={consultationButton}
                className="focus-button"
                aria-label="Open booking modal"
              />
              <AnimatedButton
                showArrow
                variant="outline"
                aria-label="Navigate to services page"
                className="w-full sm:w-auto focus-button"
                onClick={() => (window.location.href = "/services")}
              >
                {servicesButton}
              </AnimatedButton>
            </div>
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-24 lg:flex lg:items-end">
          <Image
      src="/images/home/hero-image.svg"
      alt="Hero illustration"
      width={300}
      height={400}
      priority={true}
      quality={75} // Reduced from 100 since it's an SVG
      sizes="(max-width: 768px) 200px, 300px"
      className="w-[200px] md:w-[300px] h-auto"
      placeholder="blur"
      blurDataURL="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E"
    />

          </div>
        </motion.div>
      )}
    </section>
  );
};

export default HeroSection;
