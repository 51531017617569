"use client";

import React, { useEffect, useRef } from "react";
import Image from "next/image";
import BookNowButton from "@/app/components/ui/BookNowButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";
import { useContent } from "@/utils/hooks/useContent";

gsap.registerPlugin(ScrollTrigger);

const WebAccessibilitySection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { animationsDisabled } = useAnimationDisableToggle();

  const subtitle = useContent("webAccessibilitySection", "subtitle");
  const title = useContent("webAccessibilitySection", "title");
  const stats = [
    useContent("webAccessibilitySection", "stats.0"),
    useContent("webAccessibilitySection", "stats.1"),
    useContent("webAccessibilitySection", "stats.2"),
  ];

  const buttonText = useContent("webAccessibilitySection", "buttonText");

  useEffect(() => {
    const fadeInElements = sectionRef.current?.querySelectorAll(".fade-in");

    if (animationsDisabled) {
      fadeInElements?.forEach((el) => {
        (el as HTMLElement).style.opacity = "1";
      });
    } else if (fadeInElements && fadeInElements.length > 0) {
      gsap.fromTo(
        fadeInElements,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          ease: "power1.out",
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none none",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [animationsDisabled]);

  return (
    <section
      ref={sectionRef}
      id="web-accessibility"
      className="bg-blue text-light text-center pt-8 "
      aria-labelledby="web-accessibility-title"
    >
      <div className="custom-container-lite">
        <h2
          id="web-accessibility-subtitle"
          className="text-xs xs:text-sm md:text-md py-4 fade-in"
        >
          {subtitle}
        </h2>
        <h1
          id="web-accessibility-title"
          className="text-4xl xs:text-5xl md:text-6xl font-bold fade-in pb-4"
        >
          {title}
        </h1>

        {stats.map((stat, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-md xs:text-lg md:text-xl max-w-5xl mx-auto text-center md:flex-row md:text-left my-8 lg:my-16"
          >
            <p
              className="flex-1 mb-4 md:mb-0 md:pr-4 md:mr-20 fade-in"
              aria-label={`Accessibility statistic ${index + 1}`}
              dangerouslySetInnerHTML={{ __html: stat }}
            />
        <div className="flex-shrink-0 fade-in">
  <Image
    src={`/images/home/accessibility-stat-${index + 1}.svg`}
    alt=""
    width={300}
    height={50}
    className="focus-text-dark"
    style={{ width: 'auto', height: 'auto' }}
  />
</div>

          </div>
        ))}

        <div className="flex justify-center mb-12">
          <BookNowButton
            aria-label="Open booking modal"
            showArrow={true}
            variant="light"
            buttonText={buttonText}
            className="focus-button-dark"
          />
        </div>
      </div>
    </section>
  );
};

export default WebAccessibilitySection;
