"use client";
import React, { useState, forwardRef } from "react";
import { motion, Variants, useAnimation } from "framer-motion";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";

type ButtonVariant = "primary" | "secondary" | "outline" | "light";

interface AnimatedButtonProps {
  children: React.ReactNode;
  variant?: ButtonVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showArrow?: boolean;
  className?: string;
  "aria-label"?: string;
  tabIndex?: number;
  type?: "button" | "submit" | "reset";
}

const AnimatedButton = forwardRef<HTMLButtonElement, AnimatedButtonProps>(
  (
    {
      children,
      variant = "primary",
      onClick,
      showArrow = false,
      className = "",
      "aria-label": ariaLabel,
      tabIndex = 0,
    },
    ref
  ) => {
    const controls = useAnimation();
    const [isHovered, setIsHovered] = useState(false);

    const { animationsDisabled } = useAnimationDisableToggle();

    const baseClasses = "rounded-lg py-2 overflow-hidden relative";
    const variantClasses = {
      primary: "bg-blue text-white border-2 border-blue",
      secondary: "bg-light text-blue border-2 border-blue",
      outline: "bg-transparent text-blue border-2 border-blue",
      light: "bg-transparent text-light border-2 border-light",
    };

    const textClasses = "tracking-wide";
    const shadowClasses = "shadow-lg";

    const buttonVariants: Variants = !animationsDisabled
      ? {
          rest: {
            scale: 1,
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
          },
          hover: {
            scale: 1,
            paddingLeft: "2.5rem",
            paddingRight: showArrow ? "3.5rem" : "2.5rem",
            transition: { duration: 0.3 },
          },
          tap: { scale: 0.95 },
        }
      : {
          rest: {
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
          },
          hover: {},
          tap: {},
        };

    const backgroundVariants: Variants =
      !animationsDisabled && variant === "primary"
        ? {
            hidden: { x: "-100%" },
            visible: { x: "0%" },
          }
        : {
            hidden: {},
            visible: {},
          };

    const arrowVariants: Variants = !animationsDisabled
      ? {
          hidden: { opacity: 0, x: -10 },
          visible: { opacity: 1, x: 0 },
        }
      : {
          hidden: {},
          visible: {},
        };

    const handleHoverStart = () => {
      if (!animationsDisabled) {
        setIsHovered(true);
        controls.start("visible");
      }
    };

    const handleHoverEnd = () => {
      if (!animationsDisabled) {
        setIsHovered(false);
        controls.start("hidden");
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsHovered(false);
        controls.start("hidden");
      }
    };

    return (
      <motion.button
        ref={ref}
        className={`${baseClasses} ${variantClasses[variant]}  ${shadowClasses} ${className} flex items-center justify-center`}
        onClick={onClick}
        variants={buttonVariants}
        initial="rest"
        animate={isHovered ? "hover" : "rest"}
        whileTap="tap"
        onHoverStart={handleHoverStart}
        onHoverEnd={handleHoverEnd}
        onKeyDown={handleKeyDown}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      >
        {variant === "primary" && (
          <motion.div
            className="absolute inset-0 bg-blue"
            variants={backgroundVariants}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.3 }}
          />
        )}
        <span
          className={`relative z-10 sm:text-lg text-md py-2 ${textClasses}`}
        >
          {children}
        </span>
        {!animationsDisabled && showArrow && (
          <motion.svg
            className={`w-6 h-6 absolute right-4 ${
              variant === "light" ? "text-light" : "text-current"
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            variants={arrowVariants}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.2 }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </motion.svg>
        )}
      </motion.button>
    );
  }
);

AnimatedButton.displayName = "AnimatedButton";

export default AnimatedButton;
