"use client";

import React, { useEffect, useRef } from "react";
import Image from "next/image";
import BookNowButton from "@/app/components/ui/BookNowButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAnimationDisableToggle } from "@/components/wrappers/AnimationDisableProvider";
import { useContent } from "@/utils/hooks/useContent";

gsap.registerPlugin(ScrollTrigger);

const WebFailsSection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { animationsDisabled } = useAnimationDisableToggle();

  const subtitle = useContent("webFailsSection", "subtitle");
  const title = useContent("webFailsSection", "title");
  const descriptions = [
    useContent("webFailsSection", "description.0"),
    useContent("webFailsSection", "description.1"),
  ];
  const buttonText = useContent("webFailsSection", "buttonText");

  useEffect(() => {
    const fadeInElements = sectionRef.current?.querySelectorAll(".fade-in");

    if (animationsDisabled) {
      fadeInElements?.forEach((el) => {
        (el as HTMLElement).style.opacity = "1";
      });
    } else if (fadeInElements && fadeInElements.length > 0) {
      gsap.fromTo(
        fadeInElements,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1,
          ease: "power1.out",
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none none",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [animationsDisabled]);

  return (
    <section
      ref={sectionRef}
      id="web-fails"
      className="relative w-full min-h-screen flex flex-col justify-center items-center bg-cream"
      aria-labelledby="web-fails-title"
    >
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 z-10 flex flex-col lg:flex-row items-center custom-container">
        <div className="lg:w-1/2 lg:mr-8 flex justify-center lg:justify-start">
          <Image
            src="/images/home/web-fails-image.svg"
            alt=""
            width={350}
            height={400}
            quality={100}
            className="md:w-[350px]  w-[300px] h-auto focus-text fade-in"
          />
        </div>
        <div className="lg:w-1/2 text-blue text-left max-w-2xl mt-8 lg:mt-0 fade-in">
          <h2
            id="web-fails-subtitle"
            className="text-xs xs:text-sm md:text-md py-4"
          >
            {subtitle}
          </h2>
          <h1
            id="web-fails-title"
            className="text-4xl xs:text-5xl md:text-6xl font-bold mb-4"
          >
            {title}
          </h1>
          {descriptions.map((description, index) => (
            <p
              key={index}
              className={
                index === 0
                  ? "pb-4 text-md xs:text-lg md:text-xl"
                  : "text-md xs:text-lg md:text-xl"
              }
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ))}
          <div className="mt-6 sm:mt-8 flex flex-col mb-8 sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 fade-in">
            <BookNowButton
              aria-label="Open booking modal"
              showArrow={true}
              buttonText={buttonText}
              className="focus-button"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebFailsSection;
